.form {
  @include e('control') {
    margin: 0 0 20px 0;
    position: relative;
    @include m('error') {
      .form__input {
        &::after {
          background: $red-orange-gradient;
        }
      }
      .form__label {
        color: $red;
      }
      .form__input__input {
        color: $red;
      }
      .input-wrapper__input {
        border-color: $red !important;
        background: $light-red !important;
      }
    }
    @include e('errors') {
      margin-top: 10px;
      li {
        font-size: 12px;
        font-style: italic;
      }
    }
    @include e('preview') {
      margin-top: 10px;
      width: 100px;
      border: 1px solid $gray;
      padding: 3px;
      display: inline-block;
    }
    @include e('help-text') {
      margin: 10px 0 0 0;
      font-size: 12px;
      color: lighten(black, 60%);
      display: flex;
      align-items: flex-start;
      &::before {
        content: "";
        width: 17px;
        height: 7px;
        display: inline-block;
        margin-right: 5px;
        border-left: 2px solid;
        border-bottom: 2px solid;
        opacity: 0.4;
      }
    }
  }
  @include e('label') {
    display: block;
    font-size: 16px;
    margin: 0 0 5px 0;
    @include e('text') {
      display: inline-block;
      margin-left: 10px;
    }
    @include m('checkbox') {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    @include m('radio') {
      display: flex;
      align-items: center;
      .form__label__text {
        cursor: pointer;
      }
    }
    @include m('small') {
      font-size: 12px;
    }
  }
  @include e('buttons') {}
  @include e('text-editor') {
    background: $gray !important;
    border-radius: 0 !important;
    border: 0 !important;
    @include e('toolbar') {
      border-bottom: 1px solid rgba(black, 0.1) !important;
    }
    .public-DraftEditor-content {
      min-height: 150px;
    }
    .DraftEditor-root {
      .DraftEditor-editorContainer {
        div[data-contents] {
          overflow-x: hidden;
          h1, h2, h3, h4, h5, h6, p {
            margin: 0 0 30px 0;
          }
          blockquote {
            margin: 0 0 30px 0;
            font-size: 28px;
            color: inherit;
            border-color: inherit;
          }
        }
      }
    }
  }
  @include e('radio-buttons') {
    @include m('full') {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .radio-button {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
      }
    }
  }
}

.input-and-calendar {
  .calendar {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($dark-gray, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    opacity: 0;
    animation: openOverlayCalendar 200ms forwards;
  }
  .react-calendar {
    @include smoothShadow();
    border: 0;
    border-radius: 0;
    padding: 10px;
    transform: scale(0.95);
    opacity: 0;
    animation: openCalendar 300ms forwards;
    background: darken($dark-gray, 7%);
    * {
      font-family: $base-font;
      font-size: 14px;
      color: white;
    }
    .react-calendar__navigation__label {
      font-weight: $bold-weight;
    }
    .react-calendar__navigation button,
    .react-calendar__tile {
      transition: $transition;
    }
    .react-calendar__navigation button[disabled],
    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus,
    .react-calendar__tile--hasActive,
    .react-calendar__tile--active,
    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
      background: white;
      color: $dark-gray;
      abbr {
        color: $dark-gray;
        font-weight: bold;
      }
      svg {
        fill: $dark-gray;
      }
    }
  }
}

.radio-button {
  display: inline-flex;
  text-align: center;
  .button {
    flex-grow: 1;
  }
  .radio-wrapper {
    display: none;
  }
  .form__label {
    margin: 0;
    padding: 0;
  }
  .form__label__text {
    margin: 0;
  }
  &--checked {
    .button {
      background: $medium-gray;
    }
  }
  &--icon {
    .form__label__text {
      margin: 0 0 0 10px;
    }
  }
}

.form-control-group {
  @include tabletUp {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
}

.form-control-group > * {
  @include tabletUp {
    margin: 0 10px 20px 10px;
    flex-grow: 1;
    flex-shrink: 1;
  }
}

@keyframes openOverlayCalendar {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes openCalendar {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
