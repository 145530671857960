.copy-with-input {
  display: flex;

  &__button {
    flex-shrink: 0;

    .button {
      width: 100px;
      padding: 0;
    }
  }
}
